import api from "../httpClient.js";

export async function fetchAllTags() {
  const response = await api.get("/tags");
  return response.data.tags;
}
export async function fetchOneTag(id) {
  const response = await api.put(`/tags/${id}`);
  return response.data.tag;
}
export function putTag(id, tag) {
  return api.put(`/tags/${id}`, { tag });
}
export function postTag(tag) {
  return api.post("/tags/add", { tag });
}

export function deleteTag(id) {
  return api.delete(`/tags/${id}`);
}
