<template>
  <v-container v-if="vueTags">
    <v-row>
      <v-col cols="12">
        <PageToolbar title="Tags" icon="label" dark />
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title class="lightgray align-start" v-if="vueAddTag">
            <v-text-field
              v-model="tag"
              :label="$tc('global.add') + ' Tag'"
              style="max-width: 350px"
              :rules="size"
              counter="25"
              solo
              flat
              outlined
              dense
            >
            </v-text-field>
            <v-btn
              outlined
              color="toolbar"
              class="ml-2 "
              height="40px"
              :disabled="disableTag"
              @click="createTag"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <template v-if="tags.length">
              <v-chip
                class="ma-2"
                :close="vuedelTag"
                @click:close="delTag(tag.id)"
                color="buttons2"
                text-color="white"
                v-for="(tag, index) in tags"
                :key="index"
              >
                {{ tag.tag }}
              </v-chip>
            </template>
            <template v-else>
              {{ $tc("msg.nenhumatag") }}
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { fetchAllTags, postTag, deleteTag } from "@/services/api/tags.api.js";
import { mapGetters } from "vuex";

export default {
  name: "Tags",
  props: {},
  data() {
    return {
      tag: "",
      tags: [],
      size: [(v) => v.length <= 25 || "Maximo de 25 caracters"],
    };
  },
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueTags() {
      return this.getAccess("vueTags", "menu");
    },
    vueAddTag() {
      return this.getAccess("vueTag", "adicionar");
    },
    vuedelTag() {
      return this.getAccess("vueTag", "deletar");
    },
    disableTag() {
      return this.tag ? (this.tag.length <= 25 ? false : true) : true;
    },
  },
  methods: {
    getTags() {
      this.$Progress.start();
      fetchAllTags()
        .then((response) => {
          this.tags = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    createTag() {
      if (!this.tag) return;
      this.$Progress.start();
      postTag(this.tag)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.tag = "";
            this.getTags();
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    delTag(id) {
      this.$Progress.start();
      deleteTag(id)
        .then(() => {
          this.$toast.success(this.$tc("msg.delsucesso"));
          this.getTags();
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
  },
  created() {
    this.getTags();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
